import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";
import testimonialImage from "../Assets/Images/testimonial.jpg";
// import scheduledImage from "../Assets/Images/walter-kim.png";
import quotationImage from "../Assets/Images/quotation.svg";
import upArrowImg from "../Assets/Images/up-arrow.png";
import checkupImage from "../Assets/Images/results-end.jpg";


import InviteInfo from "../Components/InviteInfo";
import Testimonial from "../Components/Testimonial";
import Resources from "../Components/Resources";
import DonutChart from "../Components/DonutChart";
import SendInvite from "../Components/SendInvite";
// import DonutChartAccordion from "../Components/DonutChartAccordion";

import { Button, Accordion, AccordionSummary, AccordionDetails, Box, Typography, LinearProgress, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, ExpandLess, ExpandMore, Remove } from "@mui/icons-material";

import useWindowDimensions from '../Hooks/useWindowDimensions';
import { sectionsText } from "../globalFunctions";
import "../App.scss";

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >
      <div className="expandIconWrapper"><Remove /></div>
      <div className="collapsIconWrapper"><Add /></div>
    </Box>
  );
};

const Results = (props) => {
  let navigate = useNavigate();
  const { id } = useParams();

  const { windowWidth } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [apiData, setApiData] = useState();
  const [name, setName] = useState("");
  const [score, setScore] = useState(0);
  const [sections, setSections] = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0, 0]);
  const [activeScoreSection, setActiveScoreSection] = useState();
  const [accordionSections, setAccordionSections] = useState([]);
  const [chartsData, setChartsData] = useState();
  const location = useLocation()

 console.log("location.state?.name", location.state?.name)

  useEffect(() => {
    // document.body.style.backgroundColor = "#435B63";
    fetchResults();
  }, []);

  useEffect(() => {
    const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
    const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
    setActiveScoreSection(sortedSectionsAndSubScores?.[0]?.section);
  }, [sections, subScores]);

  useEffect(() => {
    // basic check for API data reliability 
    if (apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        {
          title: "The community around us is aware of our mission.",
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1 }
        },
        {
          title: "How many engagement opportunities did you host in the past year open to the larger community?",
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2 }
        },
        {
          title: "Throughout last year, how many community members participated in these engagement opportunities?",
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3 }
        },
        {
          title: "Over time, our church's engagement with our community has been...",
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4 }
        },
        {
          title: "Our community frequently utilizes our church's building facilities and programs.",
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5 }
        },
      ]);
    }
  }, [apiData]);

  console.log("chartsData", chartsData);

  // to animate subScores on results page 
  useEffect(() => {
    // this settimeout is causing donutcharts selected pie enlargement to disappear so commented it out 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    //   clearTimeout(timer);
    // };

    setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);

  }, [subScore1, subScore2, subScore3, subScore4, subScore5]);

  const fetchResults = () => {
    // fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);

          setApiData(data);
          setName(data.name);
          setScore(data.score);
          setSections(data.sections);
          setAccordionSections(data.sections?.map(sec => ({ ...sec, isExpanded: false })));
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setSubScore5(data.subScore5);
          setIsMember(data.isMember);
          // console.log(data);

          setIsLoading(false);

        } else {
          // setOpenSnackbar(true);
        }
      });
  };


  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);

  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
    let currentIndex = sortedSectionsAndSubScores?.length - 2;
    while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
      highestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex--;
    }
  }

  // console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  // console.log("highestScoreObject", highestScoreObject);

  const fullName = location.state?.name || "";
  const firstName = fullName.split(" ")[0];

  const showTitleText = () => {
    if (score <= 50) {
      return <h1><span style={{ textTransform: "capitalize" }}>{ isMember === true ? firstName :  name}</span>, it seems like you might need a little help.</h1>
    } else if (score > 50 && score <= 80) {
      return <h1><span style={{ textTransform: "capitalize" }}>{ isMember === true ? firstName :  name}</span>, it seems like you can do better!</h1>
    } else if (score > 80) {
      return <h1><span style={{ textTransform: "capitalize" }}>{ isMember === true ? firstName :  name}</span>, it seems like you are doing a great job!</h1>
    }
  };

  const showScoreText = () => {
    const section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);

    // if(allScoresSame) {
    //   return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>We are glad to see that you maintain a balanced focus across all fronts of your strategy planning and execution.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some tips to take your strategy impact to the next level:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // } else {
    //     return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>Keep up the good work on your <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front and pay special attention to your <span style={{fontWeight: "700"}}>{sortedSectionsAndSubScores[0]?.section?.toLowerCase()}</span>.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some tips:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{section?.map((text, idx) => <p key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // }

    if (score <= 50) {
      return (<div className="result-text">
        <p>Your church has some opportunities to grow in stewarding its finances and aligning its dollars with its mission. We have included a resource below to help you take practical actions toward maximized progress today!</p>
      </div>);
    } else if (score > 50 && score <= 80) {
      return (<div className="result-text">
        <p>Your church is doing a good job stewarding its finances and aligning its dollars with its mission! But there might be room for improvement. We have included a resource below to help you take practical actions toward maximized progress today!</p>
      </div>);
    } else if (score > 80) {
      return (<div className="result-text">
        <p>Congratulations! Your church is doing a great job stewarding its finances and aligning its dollars with its mission! While you are performing admirably, we have included a resource below to aid you in your ongoing improvement.</p>
      </div>);
    }

  };

  const handleSectionAccordionExpansion = (idx) => {
    const newSections = [...accordionSections];
    newSections[idx].isExpanded = !newSections[idx].isExpanded;
    setAccordionSections(newSections);
  }

  const getSectionBottomBar = () => {
    //  activeScoreSection == sections[0]?.section
    if (activeScoreSection == accordionSections[0]?.section) {
      return <div className="section-bottom-text">
        <Accordion key={accordionSections[0]?.section} className="section-accordion" expanded={accordionSections[0]?.isExpanded || false}>
          <AccordionSummary expandIcon={null} onClick={() => handleSectionAccordionExpansion(0)}
            className="section-accordion-summary">
            <p style={{ fontWeight: accordionSections?.[0]?.isExpanded ? 400 : 600 }}>Looking to align your congregation with your church's mission? Here are 6 questions you must ask:</p>
            <div className="custom-accordion-control" style={{ display: accordionSections[0]?.isExpanded ? "none" : "flex", textAlign: "center" }}><span>READ MORE</span><ExpandMore /></div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>Churches need to have a clear mission to help guide the leadership and to align its people. It must be easy to understand so it is clear what actions to take to help fulfill the mission, and people know why the church must achieve its mission.</p>
              <p>One reason your congregants don't align with your church's mission is confusion or ambiguity regarding necessary actions.</p>
              <p>Here is an example of an unclear mission statement: “I want to lose weight.” It doesn't convey crucial information like how much weight is to be lost or why it is important to lose it.</p>
              <p>Here is an example of a clear mission statement: “My mission is to lose 15 pounds in the next 3 months because I want to be healthy enough to play with my kids.”</p>
              <p>Here are 6 questions to ask yourself, your leadership team, and some congregants to evaluate your mission statement:</p>
              <ul>
                <li>Can you recite your church's mission statement from memory?</li>
                <li>What are the key parts of your church's mission statement?</li>
                <li>How would you grade your church in fulfilling its mission?</li>
                <li>In what ways do you see your church fulfilling its mission?</li>
                <li>Where do you feel like you fit in your church's mission? Simply put, why do you think God brought you to your church to help fulfill its mission?</li>
                <li>What actions do you need to take to help the church fulfill its mission?</li>
              </ul>
              <div className="custom-accordion-control" style={{ display: accordionSections[0]?.isExpanded ? "flex" : "none", textAlign: "center" }}
                onClick={() => handleSectionAccordionExpansion(0)}><span>READ LESS</span><ExpandLess /></div>

            </div>
          </AccordionDetails>
        </Accordion>
        <img style={{ marginLeft: '10.5%' }} src={upArrowImg} alt="up arrow" className="up-arrow" />
      </div>
    } else if (activeScoreSection == accordionSections[1]?.section) {
      // activeScoreSection == sections[1]?.section
      return <div className="section-bottom-text">
        <Accordion key={accordionSections[1]?.section} className="section-accordion" expanded={accordionSections[1]?.isExpanded || false}>
          <AccordionSummary expandIcon={null} onClick={() => handleSectionAccordionExpansion(1)}
            className="section-accordion-summary">
            <p style={{ fontWeight: accordionSections?.[1]?.isExpanded ? 400 : 600 }}>Ensure your church is spending its money on the right things and maximizing its impact!</p>
            <div className="custom-accordion-control" style={{ display: accordionSections[1]?.isExpanded ? "none" : "flex", textAlign: "center" }}><span>READ MORE</span><ExpandMore /></div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>This is one of the biggest challenges for churches — with limited resources and increasing costs, how do you ensure your church is spending its money on the right things?</p>
              <p>It starts with planning and prioritizing your spending. That's another way of saying budgeting.</p>
              <p>But before you tune this out because you don't like budgets...</p>
              <p>A budget is simply a plan to focus your spending on the key items that will advance your mission and vision. A budget can be freeing rather than restrictive because it helps you prioritize your dollars and helps you know how much wiggle room (dollar-wise) you have for each ministry.</p>
              <p>Have trouble planning your finances? Check out our free <a href="https://liveyourparable.com/church-budgeting-toolkit/?pa_utm_source=assessment&pa_utm_medium=recommendations" target="_blank" rel="noreferrer">Budgeting Toolkit</a> and watch our <a href="https://liveyourparable.com/webinar-top-church-budgeting-mistakes/?pa_utm_source=assessment&pa_utm_medium=recommendations" target="_blank" rel="noreferrer">webinar</a> "Be a Budget Hero - Avoid the Top Church Budgeting Mistakes".</p>
              <div className="custom-accordion-control" style={{ display: accordionSections[1]?.isExpanded ? "flex" : "none", textAlign: "center" }}
                onClick={() => handleSectionAccordionExpansion(1)}><span>READ LESS</span><ExpandLess /></div>
            </div>
          </AccordionDetails>
        </Accordion>
        <img style={{ marginLeft: '47.5%' }} src={upArrowImg} alt="up arrow" className="up-arrow" />
      </div>
    } else if (activeScoreSection == accordionSections[2]?.section) {
      // activeScoreSection == sections[2]?.section
      return <div className="section-bottom-text">
        <Accordion key={accordionSections[2]?.section} className="section-accordion" expanded={accordionSections[2]?.isExpanded || false}>
          <AccordionSummary expandIcon={null} onClick={() => handleSectionAccordionExpansion(2)}
            className="section-accordion-summary">
            <p style={{ fontWeight: accordionSections?.[2]?.isExpanded ? 400 : 600 }}>$80 billion is expected to be lost to fraud by churches - ensure you have an operating system that protects you from becoming a victim!</p>
            <div className="custom-accordion-control" style={{ display: accordionSections[2]?.isExpanded ? "none" : "flex", textAlign: "center" }}><span>READ MORE</span><ExpandMore /></div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>Few things will disrupt a church more than the mishandling of money. Cases of fraud lead to a lack of trust in the leadership of the church. People stop giving and may even leave the church. Worse, the aftermath of fraud can distract leadership from being on their mission.</p>
              <p>Many pastors believe that they are not a target of fraud and that their staff and volunteers are trustworthy. Unfortunately, fraud within churches is on the rise. It is currently estimated that $80-100 billion is expected to be lost to fraud by churches every year! This is more than the amount churches send for overseas missions. It’s tragic to think that churches are expected to lose so much money that could otherwise be invested in missions for the Kingdom.</p>
              {/* <p>In 2019, they reported that more money was lost to fraud than was dedicated to supporting worldwide mission work. It's tragic to think that churches are expected to lose so much money that could otherwise be invested in missions for the Kingdom.</p> */}
              <p>But the good news is that you can greatly reduce the risk of fraud at your church by implementing sound controls and working with an accountant who understands churches.</p>
              <p>Our Church Financial Toolkit will help you understand the 7 key systems every church needs to have a healthy and thriving financial operating system.</p>

              <div className="custom-accordion-control" style={{ display: accordionSections[2]?.isExpanded ? "flex" : "none", textAlign: "center" }}
                onClick={() => handleSectionAccordionExpansion(2)}><span>READ LESS</span><ExpandLess /></div>

            </div>
          </AccordionDetails>
        </Accordion>
        <img style={{ marginLeft: '83.5%' }} src={upArrowImg} alt="up arrow" className="up-arrow" />
      </div>
    }
  };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) return null;
    else if (props?.showInvite === false) return null;
    else return (<InviteInfo />)
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this assessment!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const showTestimonial = () => {
    if (!isMember) {
      return <Testimonial />;
    } else {
      // return null;
      return <Testimonial />;
    }
  };

  const showResources = () => {
    return <Resources />
  }

  const sendInvite = () => {
    return <SendInvite />
  }

  const getLeftMargin = () => {
    if (windowWidth < 651 && score > 97) {
      return { left: 98 + "%" };
    } else if (windowWidth < 651 && score < 3) {
      return { left: 2 + "%" };
    } else {
      return { left: Math.round(score) + "%" };
    }
    // return { left: Math.round(score) + "%" };
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            {/* <img src={scheduledImage} /> */}
          </div>
          <h3>Schedule a call with Kim</h3>
          <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
          <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>TALK TO KIM</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <h1>Invite your team members</h1>
            <p>Invite your leadership team to take this assessment and begin to better align as a team around actions to maximize Kingdom impact!</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>Invite Your Team</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };



  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: '#EA1C22' }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result" style={{ position: "relative" }}>
              <div className="progress-opacity-filter" style={{ width: "100%" }}>
                <div className="transparent" style={{ width: `${Math.round(score)}%`, height: windowWidth < 651 ? "11px" : "26px", backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
                <div className="opaque" style={{ width: `${(100 - Math.round(score))}%`, height: windowWidth < 651 ? "11px" : "26px", backgroundColor: "rgba(255, 255, 255, 0.75)" }}></div>
              </div>
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: Math.round(score) + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{Math.round(score)}%</span>
                <div className="score-arrow" style={{ left: Math.round(score) + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
              </div>
              <div className="pervalue">
                <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
                {/* <h4 className="progress-20-percent" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-50-percent" style={{ minWidth: '50%' }}></h4>
                {/* <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-80-percent" style={{ minWidth: '30%' }}></h4>
                <h4 className="progress-100-percent" style={{ minWidth: '20%' }}></h4>
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>


              <div className="si_pro_header">
                <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
                {/* <h4 className="progress-20-percent" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-50-percent" style={{ minWidth: '50%' }}></h4>
                {/* <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-80-percent" style={{ minWidth: '30%' }}></h4>
                <h4 className="progress-100-percent" style={{ minWidth: '20%' }}></h4>

                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>

              {/* <div className="pervalue-text"> */}
              {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
              {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
              {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
              {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}

              <div className="pervalue-text">
                <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}>Derailing</p>
                {/* <p className="not-aligned" style={{ width: "20%", fontWeight: score > 20 && score <= 40 ? 700 : 400 }}>{windowWidth < 551 ? <span>Not Quite<br/>There</span> : <span>Not Quite There</span>}</p> */}

                <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>{windowWidth < 551 ? <span>On Track</span> : <span>On Track</span>}</p>
                <p className="some-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>{windowWidth < 551 ? <span>On Mission</span> : <span>On Mission</span>}</p>

                {/* <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Superb!</p> */}
              </div>

            </div>
          </div>
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {showScoreText()}
          {/* <h3 className="pre-score-header">Here's how you scored on your 4 Ps:</h3> */}
          <div className="score-section-container">

            {sectionsAndSubScores?.map((sec, idx) => {
              const lowestScore = sortedSectionsAndSubScores[0]?.section;
              let customColor = "#00252A";
              let customTextStyles = { color: "#343333", fontWeight: 400 };
              if (sec.section === lowestScore && sec.score < 80) {
                customColor = "#EA1C22";
                customTextStyles = { color: "#EA1C22", fontWeight: 700 };
              }
              if (activeScoreSection == sec.section) customColor = "#EA1C22";
              else { customColor = "#00252A"; }
              return (
                <div key={sec.section} className="score-section">
                  <p className="score-txt">{Math.round(sec.score)}%</p>
                  <div className="score-slider">
                    <LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)}
                      sx={{
                        backgroundColor: customColor,
                        '& .MuiLinearProgress-bar': { backgroundColor: `${customColor} !important` },
                      }} />
                    {/* <CircularProgressWithLabel size={136} customcolor={customColor} className="custom-bar" variant="determinate" value={Math.round(sec?.score)} thickness={3} /> */}
                  </div>
                  {/* <p className="score-name" style={customTextStyles}>{sec.section}</p> */}
                  <p onClick={() => setActiveScoreSection(sec.section)} style={activeScoreSection == sec.section ? { color: '#EA1C22', fontWeight: '700' } : null} className="score-name">{sec.section}</p>
                </div>)
            })}

          </div>

          {getSectionBottomBar()}

          <div style={{ height: "10px", padding: "10px" }}></div>

          {!isMember && <div className="invite-cta-btn">
            {/* <Link to={"/invite-team/" + id} target="_blank">
              <Button>INVITE MY TEAM</Button>
            </Link> */}
          </div>}

          {/* <div className="charts-accordions-container">
            {chartsData?.map((chart, idx) => {
              return (<Accordion defaultExpanded={idx === 0 ? true : false}>
                <AccordionSummary expandIcon={<CustomExpandIcon />}>
                  <p>{chart?.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <DonutChartAccordion answer={chart.answer} data={chart.values} order={chart.order} dashboard={false} />
                </AccordionDetails>
              </Accordion>);
              })
            }
          </div> */}

        </div>
      </div>

      {/* {showTeamMember()} */}

      {showLegacyInvite()}

      {/* {showInvite()} */}

      {/* {sendInvite()} */}

      <div className="check-up">
        <div className="checkup-content">
          <h1>How healthy are your church’s finances?</h1>
          <ol>
            <li>Assess your income, spending, and controls</li>
            <li>Receive a personalized report</li>
            <li>Get a free consultation with our team</li>
          </ol>
          <div className="cta-btn">
            <a rel="noreferrer" href={"https://liveyourparable.com/church-financial-health-checkup/#checkup"} target="_blank">
              <Button>Start the Check up</Button>
            </a>
          </div>
        </div>
        <div className="checkup-image">
          <img src={checkupImage} alt="checkup" />
        </div>
      </div>

      {showTestimonial()}

      {/* {showResources()} */}


      {/* <Testimonial /> */}


      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}

      {/* {showLegacyInvite()} */}

      {/* </div> */}

    </>

  );
};

export default Results;


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress variant="determinate"
        sx={{ color: "#E9E9E9 !important", position: 'absolute', }}
        size={40} thickness={4} {...props} value={100} />
      <CircularProgress variant="indeterminate" disableShrink {...props}
        value={-props.value} // to show anticlockwise progress 
        sx={{
          '&': { color: `${props.customcolor} !important`, zIndex: 1, },
          '&.MuiCircularProgress-root': {
            strokeLinecap: 'round',
          },
        }} />
      <Box sx={{
        top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}>
        <p variant="caption" component="div" color="text.primary" style={{ fontSize: "24px", fontWeight: "700", lineHeight: "30px", margin: "0 auto" }}>
          {`${Math.round(props.value)}%`}
        </p>
      </Box>
    </Box>
  );
}

